<template>
  <v-container class="mt-5">
    <v-row>
      <v-col cols="12">
        <v-toolbar dense elevation="0">
          <v-spacer />

          <v-btn
            v-if="contacts.find((c) => c.Type === 'Personal') === undefined"
            color="primary"
            class="mr-2"
            outlined
            @click="openModal('Personal')"
          >
            Add Personal Contact
          </v-btn>

          <v-btn
            v-if="contacts.find((c) => c.Type === 'Work') === undefined"
            color="secondary"
            outlined
            class="mr-2"
            @click="openModal('Work')"
          >
            Add Work Contact
          </v-btn>
        </v-toolbar>
      </v-col>

      <v-col cols="12">
        <alert />
      </v-col>

      <v-col
        cols="12"
        md="6"
        class="mt-n3"
        v-for="(contact, i) in contacts"
        :key="i"
      >
        <v-card>
          <v-app-bar flat elevation="0">
            <v-toolbar-title>{{ contact.Type }} Contact</v-toolbar-title>

            <v-spacer />

            <v-chip
              :class="`${
                contact.Status === 'Active' ? 'success' : 'primary'
              } mx-2`"
              >{{ contact.Status }}</v-chip
            >

            <v-btn icon>
              <v-icon color="blue" @click="update(contact)">mdi-pencil</v-icon>
            </v-btn>

            <v-btn icon>
              <v-icon color="red" @click="remove(contact)">mdi-delete</v-icon>
            </v-btn>
          </v-app-bar>

          <v-card-text>
            <v-list dense>
              <v-list-item-group>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-at</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Email</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ contact.Email }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-cellphone</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Phone</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ contact.PhoneNo }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-account-alert</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      >Alternative Phone Number</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      {{ contact.PhoneNo2 }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-map-marker</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Address</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ contact.Address }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-email</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Postal Code</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ contact.City }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="contact.Type === 'Work'">
                  <v-list-item-icon>
                    <v-icon>mdi-domain</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Place of Work</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ contact.PlaceOfWork }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="contact.Type === 'Work'">
                  <v-list-item-icon>
                    <v-icon>mdi-office-building-marker</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Location</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ contact.Location }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="contact.Type === 'Work'">
                  <v-list-item-icon>
                    <v-icon>mdi-account-tie</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Designation</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ contact.Designation }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="!minorSettings.countyField">
                  <v-list-item-icon>
                    <v-icon>mdi-city</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>County</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ contact.County }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-home-city</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>City</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ contact.PostCode }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-earth</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Country</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ contact.Country }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" v-if="contacts.length === 0">
        <v-card width="100%">
          <v-card-text class="text-center"> No contacts</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog persistent v-model="dialog" width="50%">
      <v-card elevation="0">
        <v-card-title class="primary">
          {{ edit ? "Edit" : "Add" }} {{ type }} Contact
        </v-card-title>

        <v-card-text class="mt-10">
          <v-row dense>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="formData.Email"
                :rules="rules.Email"
                ref="Email"
                @input="
                  (val) => (formData.Email = formData.Email.toUpperCase())
                "
                dense
                outlined
              >
                <template v-slot:label>
                  <div>Email <span class="red--text">*</span></div>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4"
              ><v-text-field
                v-model="formData.PhoneNo"
                :rules="rules.PhoneNo"
                ref="PhoneNo"
                type="text"
                v-on:keypress="NumbersOnly"
                dense
                outlined
              >
                <template v-slot:label>
                  <div>Phone <span class="red--text">*</span></div>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4"
              ><v-text-field
                v-model="formData.PhoneNo2"
                :rules="rules.PhoneNo2"
                type="text"
                v-on:keypress="NumbersOnly"
                ref="PhoneNo2"
                dense
                outlined
              >
                <template v-slot:label>
                  <div>Alternative Phone Number</div>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4"
              ><v-text-field
                v-model="formData.Address"
                :rules="rules.Address"
                ref="Address"
                @input="
                  (val) => (formData.Address = formData.Address.toUpperCase())
                "
                dense
                outlined
              >
                <template v-slot:label>
                  <div>Address <span class="red--text">*</span></div>
                </template>
              </v-text-field></v-col
            >
            <v-col v-if="type === 'Work'" cols="12" md="4">
              <v-text-field
                v-model="formData.PlaceOfWork"
                :rules="rules.PlaceOfWork"
                ref="PlaceOfWork"
                @input="
                  (val) =>
                    (formData.PlaceOfWork = formData.PlaceOfWork.toUpperCase())
                "
                dense
                outlined
              >
                <template v-slot:label>
                  <div>Place of Work <span class="red--text">*</span></div>
                </template>
              </v-text-field>
            </v-col>
            <v-col v-if="type === 'Work'" cols="12" md="4">
              <v-text-field
                v-model="formData.Location"
                :rules="rules.Location"
                ref="Location"
                @input="
                  (val) => (formData.Location = formData.Location.toUpperCase())
                "
                dense
                outlined
              >
                <template v-slot:label>
                  <div>Location <span class="red--text">*</span></div>
                </template>
              </v-text-field>
            </v-col>
            <v-col v-if="type === 'Work'" cols="12" md="4">
              <v-text-field
                v-model="formData.Designation"
                :rules="rules.Designation"
                ref="Designation"
                dense
                @input="
                  (val) =>
                    (formData.Designation = formData.Designation.toUpperCase())
                "
                outlined
              >
                <template v-slot:label>
                  <div>Designation <span class="red--text">*</span></div>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                dense
                outlined
                :items="countries"
                v-model="formData.Country"
                :rules="rules.Country"
                ref="Country"
                item-value="code"
                item-text="displayName"
              >
                <template v-slot:label>
                  <div>Country <span class="red--text">*</span></div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="4" v-if="formData.Country === 'KE'">
              <v-select
                dense
                outlined
                :items="cities"
                v-model="formData.City"
                :rules="rules.City"
                ref="City"
                item-value="Code"
                item-text="City"
              >
                <template v-slot:label>
                  <div>City <span class="red--text">*</span></div>
                </template>
              </v-select>
            </v-col>
            <v-col
              cols="12"
              md="4"
              v-if="formData.Country === 'KE' && !minorSettings.countyField"
            >
              <v-select
                dense
                outlined
                :items="counties"
                v-model="formData.County"
                :rules="!minorSettings.countyField ? '' : rules.County"
                ref="County"
                item-value="CountyNo"
                item-text="countyName"
              >
                <template v-slot:label>
                  <div>County <span class="red--text">*</span></div>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="clearForm()">Cancel</v-btn>
          <v-btn color="primary" @click="save"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import SetupMixin from "../../../setup/SetupMixin";
import { AuthService } from "../../../auth";
import Alert from "../../../../components/Alert";

export default {
  name: "Contact",
  components: { Alert },
  mixins: [SetupMixin],
  data() {
    return {
      dialog: false,
      type: "",
      edit: true,
      formData: {
        Address: "",
        City: "",
        County: "",
        Country: "",
        Designation: "",
        Email: "",
        Location: "",
        PhoneNo: "",
        PhoneNo2: "",
        PlaceOfWork: "",
      },
    };
  },

  mounted() {
    this.getSetups(["getCountries", "getCities", "getCounties"]);
    this.$store.dispatch("Profile/getContacts");
  },

  computed: {
    rules() {
      return {
        Address: [(v) => !!v || "Address is required"],
        City: [(v) => !!v || "City is required"],
        County: [(v) => !!v || "County is required"],
        Country: [(v) => !!v || "Country is required"],
        Designation: [(v) => !!v || "Designation is required"],
        Email: [
          (v) => !!v || "Email is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        Location: [(v) => !!v || "Location is required"],
        PhoneNo: [(v) => !!v || "PhoneNo is required"],
        PlaceOfWork: [(v) => !!v || "Place of Work is required"],
      };
    },

    contacts() {
      return this.$store.getters["Profile/contacts"];
    },
  },

  methods: {
    openModal: function (type) {
      this.dialog = true;
      this.type = type;

      if (type === "Personal") {
        this.formData.Email = AuthService.user.email;
      }
    },

    clearForm: function () {
      this.formData = {
        Address: "",
        City: "",
        County: "",
        Country: "",
        Designation: "",
        Email: "",
        Location: "",
        PhoneNo: "",
        PhoneNo2: "",
        PlaceOfWork: "",
      };

      this.dialog = false;
    },

    update: function (contact) {
      for (let key in this.formData) {
        this.formData[key] = contact[key];
      }

      this.type = contact.Type;
      this.edit = true;
      this.dialog = true;
    },

    remove: function (contact) {
      //Todo Confirmation
      this.$store.dispatch("Profile/removeContact", { ...contact });
    },

    save: function () {
      let isValid = true;
      for (const key in this.formData) {
        if (this.$refs[key])
          isValid = isValid ? this.$refs[key].validate(true) : false;
      }

      if (isValid) {
        this.$store.dispatch(`Profile/saveContact`, {
          Type: this.type,
          ProfileID: AuthService.user.profile.ProfileID,
          ...this.formData,
        });

        this.clearForm();
      }
    },

    NumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped></style>
