<template>
  <!-- <v-container class="mt-5"> -->
  <v-data-table :headers="headers" :items="payments" dense class="elevation-1">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title class="text-h6">My Payment History</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn
          class="text--capitalize primary"
          @click="exportToExcel"
          :disabled="payments.length === 0"
        >
          Export to Excel
          <v-icon class="mx-2"> mdi-file-excel </v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider />
    </template>
    <template v-slot:item.paymentDate="{ item }">
      {{ formatDate(item.paymentDate) }}
    </template>
    <template v-slot:item.paymentFrom="{ item }">
      {{ formatDate(item.paymentFrom) }}
    </template>
    <template v-slot:item.paymentTo="{ item }">
      {{ formatDate(item.paymentTo) }}
    </template>
    <template v-slot:item.amount="{ item }">
      {{ item.amount | currency }}
    </template>
  </v-data-table>
  <!-- </v-container> -->
</template>

<script>
import DateMixin from "@/mixins/DateMixin";
export default {
  name: "PaymentHistory",
  mixins: [DateMixin],
  data() {
    return {};
  },
  // beforeRouteEnter(to, from, next) {
  //   next((v) => {
  //     v.$store.dispatch("PaymentHistory/getHistory");
  //   });
  // },
  mounted() {
    this.$store.dispatch("PaymentHistory/getHistory");
  },
  computed: {
    headers() {
      return [
        { text: "No#", value: "paymentCode" },
        { text: "Date Of Payment", value: "paymentDate" },
        { text: "From", value: "paymentFrom" },
        { text: "To", value: "paymentTo" },
        { text: "amount", value: "amount" },
        { text: "Payment ID", value: "paymentID" },
        { text: "Payment Type", value: "paymentType" },
        { text: "Year Of Subscription", value: "yearOfSubscription" },
      ];
    },
    payments() {
      const payments = this.$store.getters["PaymentHistory/history"];
      return payments
        ? payments.sort((a, b) => b.yearOfSubscription - a.yearOfSubscription)
        : [];
    },
  },
  methods: {
    exportToExcel() {
      let data = [...this.payments];

      let csvData = data.map((datum) => {
        return {
          PaymentCode: datum.paymentCode,
          DateOfPayment: datum.paymentDate,
          PaymentOrigin: datum.paymentFrom,
          PaymentRecepient: datum.paymentTo,
          amount: datum.amount,
          PaymentID: datum.paymentID,
          PaymentType: datum.paymentType,
          YearOfSubscription: datum.yearOfSubscription,
          email: datum.email,
          name: datum.name,
          no: datum.no,
        };
      });

      let csvContent = "data:text/csv;charset=utf-8,";

      csvContent += [
        Object.keys(csvData[0]).join(","),
        ...csvData.map((item) => Object.values(item).join(",")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const csv = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", csv);
      link.setAttribute(
        "download",
        `payment-history-${this.payments[0]["memberNo"]}.csv`
      );
      link.click();
    },
  },
};
</script>

<style scoped></style>
