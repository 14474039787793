import call from "../../service/http";
import CheckListConstants from "./CheckListConstants";

export default {
  namespaced: true,
  state: {
    checklist: [],
    categoryDisclaimer: [],
    alert: {
      status: "",
      message: "",
    },
  },
  mutations: {
    SET_CHECKLIST: (state, payload) => {
      state.checklist = payload;
    },
    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },
    SET_CATEGORY_DISCLAIMER: (state, payload) => { 
      state.categoryDisclaimer = payload;
    }
  },
  getters: {
    checklist: (state) => state.checklist,
    categoryDisclaimer: (state) => state.categoryDisclaimer,
    alert: (state) => state.alert,
  },
  actions: {
    getChecklist: ({ commit }, payload) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", CheckListConstants.checklist(payload))
        .then((res) => {
          commit("SET_CHECKLIST", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
    getCategoryDisclaimer: ({ commit }, payload) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", CheckListConstants.categoryDisclaimer(payload))
        .then((res) => {
          commit("SET_CATEGORY_DISCLAIMER", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
       .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    }
  },
};
