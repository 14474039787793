import call from "../../../../service/http";
import BioConstants from "./BioConstants";

export default {
  state: {
    bio: [],
  },
  mutations: {
    SET_BIO: (state, payload) => {
      state.bio = payload;
    },
  },
  getters: {
    bio: (state) => state.bio,
  },
  actions: {
    getBio: ({ commit }) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", BioConstants.bio)
        .then((res) => {
          commit("SET_BIO", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    saveBio: async ({ commit }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });

      return call("post", BioConstants.bio, data);
    },

    updateBio: async ({ commit }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });

      return call("post", BioConstants.update, data);
    },

    approveNames: ({ commit, dispatch }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", BioConstants.approveNames, data)
        .then(() => {
          commit("SET_LOADING", false, { root: true });
          dispatch("getBio");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};
