var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fill-height d-flex flex-row align-center",staticStyle:{"background-color":"rgba(255, 255, 255, 0.95)"},attrs:{"fluid":""}},[_c('v-row',[(_vm.profile.ProfileID)?_c('v-col',{attrs:{"md":"8","offset-md":"2","sm":"12"}},[_c('v-card',{staticClass:"pa-5",attrs:{"elevation":"0"}},[_c('v-row',[_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('v-card',{attrs:{"elevation":"0"}},[(_vm.company === 'EBK')?_c('v-img',{staticClass:"rounded",attrs:{"src":'/img/avatar.jpg',"height":"200px","contain":""}}):_c('v-img',{staticClass:"rounded",attrs:{"src":_vm.profile.base64Image
                    ? `data:image/png;base64,${_vm.profile.base64Image}`
                    : '/img/avatar.jpg',"height":"200px","contain":""}}),_c('v-card-subtitle',{staticClass:"text-uppercase text-center"},[_vm._v(" "+_vm._s(`${_vm.getProfileTitle(_vm.profile.profileTitle)} ${ _vm.profile.FirstName } ${_vm.profile.SecondName} ${_vm.profile.LastName} `)+" ")])],1)],1),_c('v-col',{attrs:{"md":"8","sm":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Membership Details")]),_c('v-card-text',[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.profile.memberInformation)?_c('tbody',[(_vm.profile.memberInformation.length > 0)?_c('tr',[_c('td',[_vm._v("Membership Status")]),_c('td',[_c('v-chip',{attrs:{"small":"","color":_vm.profile.memberInformation[0].Status === 'Active'
                              ? 'green'
                              : 'deep-orange'}},[_vm._v(" "+_vm._s(_vm.profile.memberInformation[0].Status)+" ")])],1)]):_vm._e(),(_vm.profile.memberInformation.length > 0)?_c('tr',[_c('td',[_vm._v("Member No.")]),_c('td',[_vm._v(_vm._s(_vm.profile.memberInformation[0].No))])]):_vm._e(),_c('tr',[_c('td',[_vm._v("Member Category")]),_c('td',[_vm._v(_vm._s(_vm.profile.MemberShipType))])]),_c('tr',[_c('td',[_vm._v("Discipline")]),_c('td',[_vm._v(_vm._s(_vm.profile.discipline))])]),(
                        _vm.profile.contactsEntity.length > 0 &&
                        _vm.minorSettings.showWorkPlace
                      )?_c('tr',[_c('td',[_vm._v("WorkPlace")]),_c('td',[_vm._v(_vm._s(_vm.getCurrentWorkPlace()))])]):_vm._e()]):_vm._e()]},proxy:true}],null,false,1478383850)})],1)],1),_c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_vm._v("Specialization")]),_vm._l((_vm.profile.specialization),function(spec,i){return _c('v-chip',{key:i,staticClass:"ma-2",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(spec.Description)+" ")])})],2)],1)],1)],1)],1):_c('v-col',{attrs:{"md":"8","offset-md":"2","sm":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table-heading, list-item-two-line, image, table-tfoot"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }