<template>
  <v-card class="pb-12" flat>
    <v-card-title class="text-h5 justify-center member-text"
      >Upgrade plans</v-card-title
    >

    <div class="d-flex justify-center flex-wrap mx-2">
      <v-card
        v-for="(upgrade, i) in upgrades"
        :key="i"
        class="mx-auto mb-3 pa-2"
        width="45%"
        outlined
      >
        <v-card-title class="text-h5 justify-center mt-3 member-text"
          >Upgrade to
          <div class="ml-2 primary--text">
            {{ upgrade.ToCategoryDescription }}
          </div>
        </v-card-title>
        <v-card-title class="text-h5 justify-center mt-n6"
          ><div class="currency mt-n3">KES</div>
          <div class="amount-text">
            {{ upgrade.Amount | currencyFormat }}
          </div></v-card-title
        >
        <v-card-title class="text-h8 justify-center mt-n6 secondary--text"
          >Requirements</v-card-title
        >

        <!-- <div class="requirement">
          <p>
            1. Complete the checklist with the necessary requirements ( e.g
            Eduction:Final certified copies of diploma or degree in, Referees
            etc..)
          </p>
          <p>2. Payment (Make sure payment is fully paid)</p>
        </div> -->

        <v-card-text class="text-justify my-4 pb-0">
          <v-list dense flat>
            <v-list-item-group>
              <v-list-item v-for="(item, i) in checkList" :key="i">
                <v-list-item-avatar>
                  <v-icon>{{ `mdi-numeric-${i + 1}-circle` }}</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    <div style="white-space: normal">
                      {{ item.description }}
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>

        <v-card-actions class="pa-3">
          <v-btn
            :to="{
              name: 'UpgradeChecklist',
              params: { code: upgrade.ToCategory },
            }"
            rounded
            block
            color="primary"
          >
            Select
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "UpgradeCard",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Upgrade/getPlans");
    });
  },
  computed: {
    upgrades() {
      return this.$store.getters["Upgrade/plans"];
    },
    checkList() {
      return this.$store.getters["Upgrade/checklist"];
    },
  },
  watch: {
    upgrades() {
      if (this.upgrades.length !== 0)
        this.$store.dispatch("Upgrade/certifiedChecklist", {
          category: this.upgrades[0].ToCategory,
          type: "Upgrade",
        });
    },
  },
};
</script>

<style scoped>
.member-text {
  color: #3c4b64;
  font-weight: 500;
}

.amount-text {
  color: #81a333;
  font-weight: 500;
  margin-left: 6px;
}

.currency {
  font-size: 16px;
  font-weight: 500;
  color: #81a333;
}

.requirement {
  font-size: 13px;
  padding: 40px;
}
</style>
