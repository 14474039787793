<template>
  <v-container>
    <v-card elevation="0">
      <v-card-text>
        <v-btn color="primary" @click="$router.go(-1)">
          <v-icon left>mdi-arrow-left</v-icon>
          Back to checklist
        </v-btn>
      </v-card-text>

      <component v-bind:is="component" />
    </v-card>
  </v-container>
</template>

<script>
import Bio from "../../profile/components/bio/Bio";
import Contact from "../../profile/components/contact/Contact";
import Experience from "../../profile/components/experience/Experience";
import Certification from "../../profile/components/certification/Certification";
import Referee from "../../profile/components/referee/Referee";
import Specialization from "../../profile/components/specialization/Specialization";
import Conviction from "../../profile/components/conviction/Conviction";
import Attachments from "../../profile/components/attachment/Attachments";
import Services from "../../profile/components/services/Services.vue";
import Directors from "../../profile/components/directors/Director.vue";
import FirmEmployee from "../../profile/components/employees/Employee.vue";
import Shareholders from "../../profile/components/shareholders/Shareholder.vue";
import FirmBankers from "../../profile/components/bankers/Banker.vue";
import Facilities from "../../profile/components/facilities/Facility.vue";
import Institution from "../../profile/components/institution/Institution.vue";

export default {
  name: "ComponentCard",
  data: function () {
    return {
      modal: false,
      component: { template: "<div>Loading Component</div>" },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.loadComponent(to.params.name);
      
    });
  },
  methods: {
    loadComponent: function (component) {
      switch (component) {
        case "PROFILE":
        case "PICTURE":
        case "RESIDENCE":
        case "ID":
          this.component = Bio;
          break;
        case "CONTACTS":
        case "ADDRESS":
          this.component = Contact;
          break;
        case "OLEVEL":
        case "EDUCATION":
          this.component = Certification;
          break;
        case "PROFESSIONAL":
          this.component = Institution;
          break;
        case "WORK EXPERIENCE":
          this.component = Experience;
          break;
        case "REFEREE":
        case "PROPOSER":
        case "SECONDER":
          this.component = Referee;
          break;
        case "SPECILIZATION":
          this.component = Specialization;
          break;
        case "CONVICTION":
          this.component = Conviction;
          break;
        case "ATTACHMENTS":
          this.component = Attachments;
          break;
        case "SERVICES":
          this.component = Services;
          break;
        case "DIRECTORS":
          this.component = Directors;
          break;
        case "FIRMEMPLOYEES":
          this.component = FirmEmployee;
          break;
        case "SHAREHOLDERS":
          this.component = Shareholders;
          break;
        case "FIRMBANKERS":
          this.component = FirmBankers;
          break;
        case "FACILITIES":
          this.component = Facilities;
          break;
        default:
          this.component = { template: "<div>Loading Component</div>" };
      }
    },
  },
};
</script>

<style scoped></style>
