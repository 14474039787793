<template>
  <v-container class="mt-5">
    <v-data-table :headers="headers" :items="subscriptions" class="elevation-1">
      <template v-slot:item.lastPaidDate="{ item }">
        <span>{{ getDate(item.lastPaidDate) }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn-toggle>
          <v-btn
            outlined
            small
            v-if="item.billed"
            color="primary"
            @click="
              $root.routeTo({
                name: 'invoice',
                params: { code: encodeRoute(item.documentNo) },
              })
            "
          >
            <v-icon color="primary" left small> mdi-file </v-icon>
            Invoice
          </v-btn>

          <v-btn
            v-if="
              item.paid &&
              profile.MemberShipType !== 'GRADUATE' &&
              company !== 'ICS'
            "
            :to="{
              name: 'SubCertificate',
              params: { code: item },
            }"
            outlined
            small
          >
            <v-icon left small> mdi-file </v-icon>
            Certificate
          </v-btn>

          <v-btn
            v-if="item.paid && item.recieptNo"
            outlined
            small
            color="secondary"
            @click="
              $root.routeTo({
                name: 'receipt',
                params: { code: encodeRoute(item.recieptNo) },
              })
            "
          >
            <v-icon color="secondary" left small> mdi-file </v-icon>
            Receipt
          </v-btn>

          <v-btn
            v-if="!item.fullyPaid"
            outlined
            small
            color="secondary"
            @click="pay(item)"
          >
            Pay Now
            <v-icon color="secondary" right small> mdi-arrow-right </v-icon>
          </v-btn>
        </v-btn-toggle>
      </template>
    </v-data-table>
    <div class="mt-3" v-if="company === 'IEK'">
      <PaymentHistory />
    </div>
    <Checkout />
  </v-container>
</template>

<script>
import { appUrl } from "../../../environment/environment";
import { AuthService } from "../../auth";
import PaymentHistory from "../../payment-history/components/PaymentHistory.vue";
import Checkout from "@kinetics254/paygate/src/views/Checkout.vue";
import RouterMixin from "@/router/RouterMixin.js";

export default {
  name: "SubscriptionList",
  components: {
    PaymentHistory,
    Checkout,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.dispatch("Subscriptions/getSubs");
    });
  },
  mixins: [RouterMixin],

  data: () => ({
    headers: [
      {
        text: "Period",
        align: "start",
        sortable: false,
        value: "subsPeriod",
      },
      { text: "Category", value: "memberCategory" },
      { text: "Status", value: "status" },
      { text: "Amount", value: "billedAmount" },
      { text: "Amount Paid", value: "paidAmount" },
      { text: "Date Paid", value: "lastPaidDate" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    company: process.env.VUE_APP_ORGANIZATION,
  }),

  computed: {
    subscriptions() {
      return this.$store.getters["Subscriptions/subscriptions"];
    },
    profile() {
      return AuthService.user.profile;
    },
  },

  methods: {
    pay: function (data) {
      const PhoneNo =
        this.profile.PhoneNo.charAt(0) === "+"
          ? this.profile.PhoneNo.slice(1)
          : this.profile.PhoneNo.charAt(0) === "0"
          ? `254${this.profile.PhoneNo.slice(1)}`
          : this.profile.PhoneNo;

      let payload = {
        payerID: data.profileID,
        sourceDocumentNo: data.documentNo,
        serviceID: data.serviceRequestCode,
        description: "Subscription Payment",
        amount: data.billedAmount,
        accountNo: PhoneNo,
        email: this.profile.Email,
        idNumber: this.profile.IdentificationDocumentNo,
        name: `${this.profile.FirstName} ${this.profile.LastName}`,
        success_url: new URL(
          this.$router.resolve({ name: "subscriptionList" }).href,
          appUrl
        ),
        fail_url: window.location.href,
      };

      payload = btoa(JSON.stringify(payload));
      // window.location.href = new URL(`?payload=${payload}`, payGateUrl);
      this.$checkout.pay(payload);
    },

    getDate: function (item) {
      const dateString = item.slice(0, 10);
      return dateString;
    },
  },
};
</script>

<style scoped></style>
