<template>
  <v-container class="mt-5">
    <alert />

    <p class="red--text font-italic caption pa-3">
      <v-icon color="red" small>mdi-alert</v-icon>
      All documents should be scanned certified colour copy. Certification to be
      done by the Commissioner of Oaths whose names and address are fully
      displayed on the Rubber Stamp
    </p>

    <v-data-table
      :headers="headers"
      :items="certifications.filter((c) => c.Type === 'Professional')"
      dense
      class="elevation-1"
      :loading="$store.getters['loading']"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>ACADEMIC QUALIFICATIONS</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog persistent v-model="dialog" max-width="50%">
            <template v-slot:activator="{}">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Add Certification
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      @click="
                        openDialog({ locale: 'Local', type: 'Professional' })
                      "
                    >
                      <v-list-item-title>Local University</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="
                        openDialog({
                          locale: 'Foreign',
                          type: 'Professional',
                        })
                      "
                    >
                      <v-list-item-title>Foreign University</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </template>
            <v-card>
              <v-card-title class="primary">
                <span class="text-h5">
                  {{ edit ? "Edit" : "Add" }} Qualification
                </span>
              </v-card-title>

              <v-card-text class="mt-10">
                <v-container>
                  <v-form ref="engineeringForm">
                    <v-alert
                      dense
                      border="left"
                      type="warning"
                      v-if="formData.Category === 'Foreign'"
                    >
                      Make sure the Degree/Certificate is translated
                    </v-alert>
                    <v-row dense>
                      <!-- local universities -->
                      <v-col
                        v-if="
                          formData.Category === 'Local' &&
                          formData.Type === 'Professional' &&
                          minorSettings.organisation_caption === 'EBK'
                        "
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-autocomplete
                          dense
                          outlined
                          :items="localUniversities"
                          @input="setAccreditedInstituteFunc($event)"
                          :item-text="(item) => item.description"
                          :item-value="(item) => item.code"
                          :rules="rules.UniversityCode"
                          v-model="setaccreditedInstitute"
                          @click:clear="clearInstitute"
                          ref="institute"
                        >
                          <template v-slot:label>
                            <div>
                              Institution <span class="red--text">*</span>
                            </div>
                          </template>
                          <template v-slot:selection="{ item }">
                            <span class="selectWrap">
                              {{
                                item.code === "Other"
                                  ? isOther()
                                  : item.description
                              }}
                            </span>
                          </template>
                        </v-autocomplete>
                        <v-text-field
                          v-if="other"
                          class="label"
                          label="Accredited Institute"
                          dense
                          v-model="setaccreditedInstitute"
                          @input="setCustomInstituteFunc($event)"
                          clearable
                          @click:clear="clearCustomInstitute"
                          outlined
                          ref="otherInstitute"
                        />
                      </v-col>

                      <!-- fallback for auto-complete -->

                      <v-col v-else cols="12" sm="6" md="4">
                        <v-text-field
                          dense
                          outlined
                          v-model="formData.Institution"
                          :rules="rules.Institution"
                          @input="
                            () =>
                              (formData.Institution =
                                formData.Institution.toUpperCase())
                          "
                          ref="Institution"
                        >
                          <template v-slot:label>
                            <div>
                              Institution <span class="red--text">*</span>
                            </div>
                          </template>
                        </v-text-field>
                      </v-col>

                      <!-- AreaOfSpecialization -->
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        v-if="formData.Type === 'Professional'"
                      >
                        <v-autocomplete
                          v-if="
                            minorSettings.organisation_caption === 'EBK' &&
                            accreditedPrograms &&
                            accreditedPrograms.length !== 0
                          "
                          dense
                          outlined
                          :items="accreditedPrograms"
                          v-model="formData.AreaOfSpecialization"
                          :rules="rules.AreaOfSpecialization"
                          ref="AreaOfSpecialization"
                          item-value="code"
                          item-text="description"
                          placeholder="i.e Civil Engineering"
                          messages="i.e Civil Engineering"
                          @change="checkIfOther"
                        >
                          <template v-slot:label>
                            <div>
                              Engineering Discipline
                              <span class="red--text">*</span>
                            </div>
                          </template>
                        </v-autocomplete>

                        <v-text-field
                          v-if="
                            isOtherDisciplineSelected ||
                            accreditedPrograms.length === 0
                          "
                          dense
                          outlined
                          v-model="formData.AreaOfSpecialization"
                          :rules="rules.AreaOfSpecialization"
                          ref="AreaOfSpecialization"
                          :messages="specializationCaption"
                        >
                          <template v-slot:label>
                            <div>
                              {{
                                minorSettings.organisation_caption === "EBK"
                                  ? "Engineering Discipline"
                                  : "Academic Qualification"
                              }}
                              <span class="red--text">*</span>
                            </div>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-autocomplete
                          dense
                          outlined
                          label="Certificate Awarded"
                          v-if="
                            !formData.isOLevel &&
                            formData.Category === 'Local' &&
                            formData.Type === 'Professional'
                          "
                          :items="
                            certificationCodes.filter(
                              (c) => c.Code === 'DEGREE'
                            )
                          "
                          v-model="formData.CertificateAwarded"
                          :rules="rules.CertificateAwarded"
                          ref="CertificateAwarded"
                          item-value="Code"
                          item-text="Code"
                        >
                          <template v-slot:label>
                            <div>
                              Certificate Awarded
                              <span class="red--text">*</span>
                            </div>
                          </template>
                        </v-autocomplete>
                        <v-autocomplete
                          v-else
                          dense
                          outlined
                          label="Certificate Awarded"
                          :items="
                            formData.isOLevel
                              ? certificationCodes.filter((c) => c.oLevel)
                              : certificationCodes.filter((c) => !c.oLevel)
                          "
                          v-model="formData.CertificateAwarded"
                          :rules="rules.CertificateAwarded"
                          ref="CertificateAwarded"
                          item-value="Code"
                          item-text="Code"
                        >
                          <template v-slot:label>
                            <div>
                              Certificate Awarded
                              <span class="red--text">*</span>
                            </div>
                          </template>
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <!-- date picker menu -->
                        <v-menu
                          ref="menu"
                          v-model="startDateMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              dense
                              outlined
                              v-model="formData.StartDate"
                              :rules="rules.StartDate"
                              ref="StartDate"
                              label="Start Date"
                              readonly
                              v-on="on"
                            >
                              <template v-slot:label>
                                <div>
                                  Start Date <span class="red--text">*</span>
                                </div>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="formData.StartDate"
                            no-title
                            scrollable
                            :max="today"
                            @input="startDateMenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <!-- date picker menu -->
                        <v-menu
                          ref="menu"
                          v-model="endDateMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              dense
                              outlined
                              v-model="formData.EndDate"
                              :rules="rules.EndDate"
                              ref="EndDate"
                              label="End Date"
                              readonly
                              v-on="on"
                            >
                              <template v-slot:label>
                                <div>
                                  End Date <span class="red--text">*</span>
                                </div>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="formData.EndDate"
                            no-title
                            scrollable
                            :max="today"
                            @input="endDateMenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        v-if="!formData.Type === 'Educational'"
                      >
                        <!-- date picker menu -->
                        <v-menu
                          ref="menu"
                          v-model="GraduationDateMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              dense
                              outlined
                              v-model="formData.GraduationDate"
                              :rules="rules.GraduationDate"
                              ref="GraduationDate"
                              label="Graduation Date"
                              readonly
                              v-on="on"
                            >
                              <template v-slot:label>
                                <div>
                                  Graduation Date
                                  <span class="red--text">*</span>
                                </div>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="formData.GraduationDate"
                            no-title
                            scrollable
                            :max="today"
                            @input="GraduationDateMenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          dense
                          outlined
                          v-model="formData.CertificateNo"
                          :rules="rules.CertificateNo"
                          ref="CertificateNo"
                          @input="
                            () =>
                              (formData.CertificateNo =
                                formData.CertificateNo.toUpperCase())
                          "
                          messages="Use N/A if you do not possess a certificate."
                        >
                          <template v-slot:label>
                            <div>
                              Certificate No. <span class="red--text">*</span>
                            </div>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-file-input
                          outlined
                          dense
                          ref="File"
                          accept="application/pdf"
                          hint="pdf documents only"
                          persistent-hint
                          type="file"
                          v-model="formData.File"
                          :rules="rules.File"
                          :append-outer-icon="
                            formData.Cert ? 'mdi-download' : ''
                          "
                          @click:append-outer="downloadFile(formData.Cert)"
                        >
                          <template v-slot:label>
                            <div>
                              Attach Certified Certificate
                              <span class="red--text">*</span>
                            </div>
                          </template>
                        </v-file-input>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        v-if="formData.Category === 'Foreign'"
                      >
                        <v-autocomplete
                          dense
                          outlined
                          :items="countries"
                          v-model="formData.countryOfUniversity"
                          :rules="rules.Country"
                          ref="Country"
                          item-value="code"
                          item-text="displayName"
                        >
                          <template v-slot:label>
                            <div>Country <span class="red--text">*</span></div>
                          </template>
                        </v-autocomplete>
                      </v-col>

                      <v-col
                        v-if="formData.Category === 'Local'"
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-autocomplete
                          dense
                          outlined
                          label="County"
                          :items="counties"
                          :item-value="(item) => item.CountyNo"
                          :item-text="(item) => item.countyName"
                          v-model="formData.County"
                          :rules="rules.County"
                          ref="City"
                        >
                          <template v-slot:label>
                            <div>County <span class="red--text">*</span></div>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <!-- <v-col
                        v-if="formData.Category === 'Local'"
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-autocomplete
                          dense
                          outlined
                          label="Town"
                          :items="filteredCities"
                          :item-value="(item) => item.City"
                          :item-text="(item) => item.City"
                          v-model="formData.city"
                          :rules="rules.Town"
                          ref="Town"
                        >
                          <template v-slot:label>
                            <div>Town <span class="red--text">*</span></div>
                          </template>
                        </v-autocomplete>
                      </v-col> -->

                      <v-col
                        v-if="
                          formData.Type === 'Educational' &&
                          formData.Category !== 'Local'
                        "
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-text-field
                          dense
                          outlined
                          v-model="formData.location"
                          :rules="rules.location"
                          ref="Location"
                          @input="
                            () =>
                              (formData.location =
                                formData.location.toUpperCase())
                          "
                        >
                          <template v-slot:label>
                            <div>Location <span class="red--text">*</span></div>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <fieldset
                      style="padding: 16px 20px !important; border-radius: 12px"
                      v-if="
                        (formData.isOLevel &&
                          formData.Category === 'Local' &&
                          formData.Type === 'Educational') ||
                        (isOLevelCert &&
                          formData.Category === 'Local' &&
                          formData.Type === 'Educational')
                      "
                    >
                      <legend>
                        O Level Aggregate grade and Subjects Scores
                      </legend>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-autocomplete
                            dense
                            outlined
                            v-model="formData.Division"
                            :items="
                              divisions.filter(
                                (d) =>
                                  d.certCode === formData.CertificateAwarded
                              )
                            "
                            :item-value="(item) => item.code"
                            :item-text="(item) => item.description"
                            :rules="rules.Division"
                            ref="AggregateGrade"
                            @input="
                              () =>
                                (formData.Division =
                                  formData.Division.toUpperCase())
                            "
                          >
                            <template v-slot:label>
                              <div>
                                Aggregate grade <span class="red--text">*</span>
                              </div>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            dense
                            outlined
                            v-model="certificationSubjects.Mathematics"
                            :rules="rules.Mathematics"
                            placeholder="e.g. A"
                            ref="Mathematics"
                            @input="
                              () =>
                                (certificationSubjects.Mathematics =
                                  certificationSubjects.Mathematics.toUpperCase())
                            "
                          >
                            <template v-slot:label>
                              <div>
                                Mathematics <span class="red--text">*</span>
                              </div>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            dense
                            outlined
                            v-model="certificationSubjects.Physics"
                            :rules="rules.Physics"
                            placeholder="e.g. B+"
                            ref="Physics"
                            @input="
                              () =>
                                (certificationSubjects.Physics =
                                  certificationSubjects.Physics.toUpperCase())
                            "
                          >
                            <template v-slot:label>
                              <div>
                                Physics <span class="red--text">*</span>
                              </div>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            dense
                            outlined
                            v-model="certificationSubjects.Chemistry"
                            :rules="rules.Chemistry"
                            placeholder="e.g. A-"
                            ref="Chemistry"
                            @input="
                              () =>
                                (certificationSubjects.Chemistry =
                                  certificationSubjects.Chemistry.toUpperCase())
                            "
                          >
                            <template v-slot:label>
                              <div>
                                Chemistry <span class="red--text">*</span>
                              </div>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            dense
                            outlined
                            v-model="certificationSubjects.English"
                            :rules="rules.English"
                            ref="English"
                            @input="
                              () =>
                                (certificationSubjects.English =
                                  certificationSubjects.English.toUpperCase())
                            "
                          >
                            <template v-slot:label>
                              <div>
                                English <span class="red--text">*</span>
                              </div>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            dense
                            outlined
                            v-model="certificationSubjects.Kiswahili"
                            :rules="rules.Kiswahili"
                            placeholder="e.g. C"
                            ref="Kiswahili"
                            @input="
                              () =>
                                (certificationSubjects.Kiswahili =
                                  certificationSubjects.Kiswahili.toUpperCase())
                            "
                          >
                            <template v-slot:label>
                              <div>
                                Kiswahili <span class="red--text">*</span>
                              </div>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </fieldset>

                    <v-row
                      dense
                      class="mt-3"
                      v-if="formData.Category === 'Foreign'"
                    >
                      <v-col cols="12">
                        <h4 class="overline">Transcripts</h4>
                        <v-divider class="mb-3" />
                      </v-col>
                      <v-col cols="5">
                        <v-text-field
                          outlined
                          dense
                          v-model="transcriptForm.description"
                          @input="
                            () =>
                              (transcriptForm.description =
                                transcriptForm.description.toUpperCase())
                          "
                        >
                          <template v-slot:label>
                            <div>
                              Transcript Description
                              <span class="red--text">*</span>
                            </div>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="5">
                        <v-file-input
                          label="Transcript Attachment"
                          accept="application/pdf"
                          hint="pdf documents only"
                          persistent-hint
                          type="file"
                          outlined
                          dense
                          v-model="transcriptForm.attachment"
                        >
                          <template v-slot:label>
                            <div>
                              Transcript Attachment
                              <span class="red--text">*</span>
                            </div>
                          </template>
                        </v-file-input>
                      </v-col>

                      <v-col cols="2">
                        <v-btn @click="addTranscript" color="primary" block>
                          <v-icon left>mdi-file-plus</v-icon>
                          Add
                        </v-btn>
                      </v-col>

                      <v-col cols="12">
                        <v-chip
                          label
                          color="secondary"
                          large
                          class="ma-2"
                          @click="downloadFile(script.attachment)"
                          v-for="(script, i) in formData.transcript"
                          :key="i"
                          close
                          @click:close="removeTranscript(script)"
                        >
                          <v-icon left>mdi-file</v-icon>
                          {{ script.description }}
                        </v-chip>
                      </v-col>
                    </v-row>

                    <v-row
                      dense
                      class="mt-3"
                      v-if="
                        formData.Category === 'Foreign' ||
                        (formData.Category === 'Local' &&
                          formData.UniversityCode === 'Other') ||
                        (edit && !formData.UniversityCode && !isOLevelCert)
                      "
                    >
                      <v-col cols="12">
                        <h4 class="overline">
                          Contact Information from Institution
                        </h4>
                        <v-divider class="mb-3" />
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          dense
                          outlined
                          label="Contact Person"
                          v-model="formData.ContactPerson"
                          :rules="rules.ContactPerson"
                          @input="
                            () =>
                              (formData.ContactPerson =
                                formData.ContactPerson.toUpperCase())
                          "
                          ref="ContactPerson"
                        />
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          dense
                          outlined
                          label="Designation"
                          v-model="formData.ContactDesignation"
                          :rules="rules.ContactDesignation"
                          ref="ContactDesignation"
                          @input="
                            () =>
                              (formData.ContactDesignation =
                                formData.ContactDesignation.toUpperCase())
                          "
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-autocomplete
                          dense
                          outlined
                          label="Country"
                          :items="countries"
                          v-model="formData.ContactCountry"
                          :rules="rules.ContactCountry"
                          ref="ContactCountry"
                          item-value="code"
                          item-text="displayName"
                        />
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          dense
                          outlined
                          label="Email"
                          v-model="formData.ContactEmail"
                          :rules="rules.ContactEmail"
                          ref="ContactEmail"
                          @input="
                            () =>
                              (formData.ContactEmail =
                                formData.ContactEmail.toUpperCase())
                          "
                        />
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          dense
                          outlined
                          label="Address"
                          v-model="formData.contactPersonAddress"
                          :rules="rules.contactPersonAddress"
                          ref="contactPersonAddress"
                          @input="
                            () =>
                              (formData.contactPersonAddress =
                                formData.contactPersonAddress.toUpperCase())
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="clearForm"> Cancel </v-btn>
                <v-btn color="primary" @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.location`]="{ item }">
        <span v-if="item.Category === 'Local'">{{
          item?.counties?.countyName
        }}</span>
        <span v-else>{{ item.ContactCountry }}</span>
      </template>

      <template v-slot:item.attachment="{ item }">
        <v-btn
          small
          color="secondary"
          outlined
          @click="downloadFile(item.Cert, item.Institution)"
        >
          <v-icon left>mdi-file</v-icon>
          Download
        </v-btn>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" color="blue" @click="update(item)">
          mdi-pencil
        </v-icon>
        <v-icon small color="red" @click="remove(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <v-data-table
      :headers="
        minorSettings.organisation_caption === 'EBK'
          ? otherQualificationHeaders
          : headers
      "
      :items="
        certifications.filter((c) => c.Type === 'Educational' && c.oLevel)
      "
      dense
      class="elevation-1 mt-10"
      :loading="$store.getters['loading']"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-wrap"
            >O LEVEL QUALIFICATIONS(e.g KCSE, IGCSE)</v-toolbar-title
          >
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="primary"
            dark
            class="mb-2"
            @click="
              openDialog({
                locale: 'Local',
                type: 'Educational',
                isOLevel: true,
              })
            "
          >
            Add O Level Certificate
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.location`]="{ item }">
        <span>{{ item?.counties?.countyName }}</span>
      </template>
      <template v-slot:[`item.attachment`]="{ item }">
        <v-btn
          small
          color="secondary"
          outlined
          @click="downloadFile(item.Cert, item.Institution)"
        >
          <v-icon left>mdi-file</v-icon>
          Download
        </v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" color="blue" @click="update(item)">
          mdi-pencil
        </v-icon>
        <v-icon small color="red" @click="remove(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <v-data-table
      :headers="
        minorSettings.organisation_caption === 'EBK'
          ? otherQualificationHeaders
          : headers
      "
      :items="
        certifications.filter((c) => c.Type === 'Educational' && !c.oLevel)
      "
      dense
      class="elevation-1 mt-10"
      :loading="$store.getters['loading']"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-wrap"
            >OTHER QUALIFICATIONS (e.g
            {{
              minorSettings.organisation_caption === "EBK"
                ? "Masters, Diploma, Certificates, Bridging Certificate and Craft Certificate"
                : ""
            }})</v-toolbar-title
          >
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="primary"
            dark
            class="mb-2"
            @click="openDialog({ locale: 'Local', type: 'Educational' })"
          >
            Add Other
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.attachment="{ item }">
        <v-btn
          small
          color="secondary"
          outlined
          @click="downloadFile(item.Cert, item.Institution)"
        >
          <v-icon left>mdi-file</v-icon>
          Download
        </v-btn>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" color="blue" @click="update(item)">
          mdi-pencil
        </v-icon>
        <v-icon small color="red" @click="remove(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { AuthService } from "../../../auth";
import SetupMixin from "../../../setup/SetupMixin";
import { helper } from "../../../../utils";
import Alert from "../../../../components/Alert";
import FileMixin from "../../../../mixins/FileMixin";
import moment from "moment";

export default {
  name: "Certification",
  components: { Alert },
  mixins: [SetupMixin, FileMixin],
  data: () => ({
    certificationSubjects: {
      Mathematics: "",
      Physics: "",
      Chemistry: "",
      English: "",
      Kiswahili: "",
    },
    dialog: false,
    edit: false,
    today: new Date().toISOString().substr(0, 10),
    startDateMenu: false,
    GraduationDateMenu: false,
    endDateMenu: false,
    options: [
      { value: "Educational", label: "Others" },
      { value: "Professional", label: "Professional" },
    ],
    isOtherDisciplineSelected: false,
    transcriptForm: {
      description: "",
      attachment: "",
    },
    formData: {
      Institution: "",
      CompletionYear: 2021,
      AreaOfSpecialization: "",
      CertificateAwarded: "",
      QualificationDescription: "",
      Type: "",
      CertificateNo: "",
      CertificationClass: "",
      Category: "",
      StartDate: "",
      EndDate: "",
      GraduationDate: "",
      ContactPerson: "",
      ContactDesignation: "",
      contactPersonAddress: "",
      ContactEmail: "",
      ContactCountry: "",
      UniversityCode: "",
      File: undefined,
      Cert: "",
      transcript: [],
      location: "",
      isOLevel: null,
      countryOfUniversity: "",
      County: "",
      city: "",
      Division: "",
    },
    headers: [
      {
        text: "Institution",
        align: "start",
        sortable: false,
        value: "Institution",
      },
      { text: "Engineering Discipline", value: "AreaOfSpecialization" },
      { text: "Locale", value: "Category" },
      { text: "From", value: "StartDate" },
      { text: "To", value: "EndDate" },
      { text: "Location", value: "location" },
      { text: "Attachment", value: "attachment", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  mounted() {
    this.getSetups([
      "getQualificationCodes",
      "getCertificationCodes",
      "getDivisions",
      "getUniversities",
      "getCountries",
      "getSpecializationTypes",
      "getCounties",
      "getCities",
      "getPostalcodes",
    ]);
    this.$store.dispatch("Profile/getCertifications");
  },

  computed: {
    isOLevelCert() {
      return this.formData.isOLevel || this.formData.oLevel;
    },
    counties: function () {
      return this.$store.getters["Setup/counties"];
    },
    rules: function () {
      return {
        Institution: [(v) => !!v || "Institution is required"],
        location: [(v) => !!v || "Location is required"],
        CompletionYear: [(v) => !!v || "Completion Year is required"],
        AreaOfSpecialization: [
          (v) => !!v || "Area of Specialization is required",
        ],
        CertificateAwarded: [(v) => !!v || "Certificate Awarded is required"],
        Type: [(v) => !!v || "Type is required"],
        CertificateNo: [(v) => !!v || "Certificate No is required"],
        CertificationClass: [(v) => !!v || "Certification Class is required"],
        Category: [(v) => !!v || "Category is required"],
        StartDate: [(v) => !!v || "Start Date is required"],
        Country: [(v) => !!v || "Country is required"],
        County: [(v) => !!v || "County is required"],
        Town: [(v) => !!v || "Town is required"],
        EndDate: [
          (v) => !!v || "End Date is required",
          (v) =>
            moment(this.formData.StartDate).isBefore(moment(v)) ||
            "End Date Cannot come before Start Date",
        ],
        GraduationDate: [
          (v) => !!v || "Graduation Date is required",
          (v) =>
            moment(this.formData.StartDate).isBefore(moment(v)) ||
            "Graduation Date Cannot come before Start Date",
        ],
        UniversityCode: [
          (v) =>
            ((this.formData.Category === "Local" ||
              this.formData.Category === "Foreign") &&
              !!v) ||
            "University is required",
        ],
        ContactPerson: [
          (v) =>
            (this.formData.Category === "Foreign" ||
            (this.formData.Category === "Local" &&
              this.formData.UniversityCode === "Other")
              ? !!v
              : true) || "Contact Person is required",
        ],
        ContactDesignation: [
          (v) =>
            (this.formData.Category === "Foreign" ||
            (this.formData.Category === "Local" &&
              this.formData.UniversityCode === "Other")
              ? !!v
              : true) || "Designation is required",
        ],
        ContactEmail: [
          (v) =>
            (this.formData.Category === "Foreign" ||
            (this.formData.Category === "Local" &&
              this.formData.UniversityCode === "Other")
              ? !!v
              : true) || "Email is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        contactPersonAddress: [
          (v) =>
            (this.formData.Category === "Foreign" ||
            (this.formData.Category === "Local" &&
              this.formData.UniversityCode === "Other")
              ? !!v
              : true) || "Address is required",
        ],
        ContactCountry: [
          (v) =>
            (this.formData.Category === "Foreign" ||
            (this.formData.Category === "Local" &&
              this.formData.UniversityCode === "Other")
              ? !!v
              : true) || "Country is required",
        ],
        File: [
          (v) => {
            return this.formData.Cert === ""
              ? !!v || "Attachment is required"
              : true;
          },

          (v) => {
            return v
              ? (!!v && v.size < 10000000) ||
                  "Attachment should be less than 10MB"
              : true;
          },

          (v) => {
            return v
              ? (!!v && v.type.includes("application/pdf")) ||
                  "Attachment must be a Pdf"
              : true;
          },
        ],
      };
    },

    otherQualificationHeaders() {
      return this.headers.filter(
        (el) => !["Locale", "Area Of Specialization"].includes(el.text)
      );
    },

    years() {
      let arr = [];
      for (let i = 1900; i <= new Date().getFullYear(); i++) {
        arr.push(i);
      }
      return arr;
    },

    certifications() {
      return this.$store.getters["Profile/certifications"];
    },

    accreditedPrograms() {
      if (this.formData.UniversityCode !== "") {
        let programs = this.universities
          .filter((u) => u.code === this.formData.UniversityCode)
          .shift();
        return programs
          ? [
              ...programs.accreditedPrograms,
              { code: "Other", description: "Other" },
            ]
          : [];
      } else {
        return [
          ...this.universities.map((u) => u.accreditedPrograms).flat(),
          { code: "Other", description: "Other" },
        ];
      }
    },

    specializationCaption() {
      if (this.minorSettings) {
        return this.minorSettings.organisation_caption === "EBK"
          ? "i.e Civil Engineering"
          : "e.g Bachelor of Arts";
      } else {
        return "";
      }
    },
    //get local & foreign uni
    localUniversities() {
      return [
        ...this.universities.filter((el) => el.foreign === false),
        { code: "Other", description: "Other" },
      ];
    },
    foreignUniversities() {
      return this.universities.filter((el) => el.foreign === true);
    },

    setaccreditedInstitute: {
      get() {
        let instituteCode = this.formData.UniversityCode;
        if (instituteCode === "Other") {
          return this.formData.Institution;
        } else {
          return instituteCode;
        }
      },
      set(value) {
        this.formData.UniversityCode = value;
      },
    },

    other: function () {
      return this.formData.UniversityCode === "Other";
    },

    filteredCities: function () {
      return this.formData.County
        ? this.cities.filter((city) => city.County === this.formData.County)
        : this.cities;
    },
  },

  methods: {
    clearForm: function () {
      this.formData = {
        Institution: "",
        CompletionYear: 2021,
        AreaOfSpecialization: "",
        CertificateAwarded: "",
        QualificationDescription: "",
        Type: "",
        CertificateNo: "",
        CertificationClass: "",
        Category: "",
        StartDate: "",
        EndDate: "",
        GraduationDate: "",
        ContactPerson: "",
        ContactDesignation: "",
        contactPersonAddress: "",
        ContactEmail: "",
        ContactCountry: "",
        UniversityCode: "",
        File: undefined,
        Cert: "",
        transcript: [],
        location: "",
        Division: "",
      };
      this.edit = false;
      this.dialog = false;
    },

    update: function (payload) {
      this.formData = { ...payload };
      this.edit = true;
      this.dialog = true;
    },

    checkIfOther(value) {
      // Check if the user selected 'other'
      this.isOtherDisciplineSelected = value === "Other";

      // Clear AreaOfSpecialization to allow user input for 'other'
      if (this.isOtherDisciplineSelected) {
        this.formData.AreaOfSpecialization = "";
      }
    },

    remove(item) {
      this.$confirm.show({
        title: "Delete this Certification?",
        text: `Are you sure you want to delete?`,
        onConfirm: () => {
          this.$store.dispatch("Profile/removeCertification", { ...item });
        },
      });
    },

    save: function () {
      let isValid = true;
      for (const key in this.formData) {
        if (this.$refs[key])
          isValid = isValid ? this.$refs[key].validate(true) : false;
      }

      if (isValid) {
        if (this.formData.UniversityCode === "Other") {
          delete this.formData.UniversityCode;
        }
        if (!this.edit && this.isOLevelCert) {
          this.$store.dispatch("Profile/addCertificationSubjects", {
            ...this.certificationSubjects,
          });
        }
        this.$store.dispatch(
          `Profile/${this.edit ? "updateCertification" : "addCertification"}`,
          helper.prepareFormData({
            ...this.formData,
            transcript: JSON.stringify([...this.formData.transcript]),
            ProfileID: AuthService.user.profile.ProfileID,
            EntryNo: this.formData.EntryNo
              ? this.formData.EntryNo
              : this.certifications.length + 1, //TODO Confirm Generation of Entry Nos.
          })
        );

        this.clearForm();
      }
    },

    addTranscript: function () {
      let reader = new FileReader();
      let data = { ...this.transcriptForm };

      reader.onloadend = () => {
        data.attachment = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");
        this.formData.transcript.push(data);
      };

      reader.readAsDataURL(this.transcriptForm.attachment);

      this.transcriptForm = {
        description: "",
        attachment: "",
      };
    },

    removeTranscript: function (script) {
      let index = this.formData.transcript.indexOf(script);
      this.formData.transcript.splice(index, 1);
    },

    openDialog: function (params) {
      this.formData.Category = params.locale;
      this.formData.Type = params.type;
      this.formData.isOLevel = params.isOLevel;
      if (!this.edit) {
        this.formData.transcript = [];
      }
      this.dialog = true;
    },

    isOther() {
      this.$nextTick(() => this.$refs.otherInstitute.focus());
    },

    clearCustomInstitute() {
      delete this.formData.UniversityCode;
      this.$nextTick(() => this.$refs.institute.focus());
    },

    clearInstitute() {
      delete this.formData.UniversityCode;
    },

    setCustomInstituteFunc(event) {
      this.formData.UniversityCode = "Other";
      this.formData.Institution = event;
    },

    setAccreditedInstituteFunc(event) {
      let currentInstitute = this.localUniversities
        ? this.localUniversities
            .filter((item) => {
              return item.code === event;
            })
            .shift()
        : "";
      this.formData.UniversityCode = event;
      this.formData.Institution = currentInstitute ? currentInstitute.name : "";
    },

    getUniversityCount(city) {
      return this.counties.filter((county) => county.CountyNo === city)[0]
        .countyName;
    },
  },
};
</script>

<style scoped></style>
