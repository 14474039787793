import call from "../../service/http";
import constants from "./UpgradeConstants";
import { EventBus } from "../../utils/eventBus";
import ApplicationConstant from "@/modules/application/ApplicationConstant";
export default {
  namespaced: true,

  state: {
    plans: [],
    upgrades: [],
    alert: {
      status: "",
      message: "",
    },
    checklist: [],
    upgradeReport: "",
    acceptanceLetter: "",
  },

  mutations: {
    SET_PLANS: (state, payload) => {
      state.plans = payload;
    },
    SET_UPGRADES: (state, payload) => {
      state.upgrades = payload;
    },
    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },
    SET_CHECKLIST: (state, payload) => {
      state.checklist = payload;
    },
    SET_UPGRADE_REPORT: (state, payload) => {
      state.upgradeReport = payload;
    },
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },

  getters: {
    plans: (state) => state.plans,
    upgrades: (state) => state.upgrades,
    alert: (state) => state.alert,
    checklist: (state) => state.checklist,
    upgradeReport: (state) => state.upgradeReport,
    upgradeGetters: (state) => (val) => state[val],
  },

  actions: {
    getPlans: ({ commit }) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", constants.plans)
        .then((res) => {
          commit("SET_PLANS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    getUpgrades: ({ commit }) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", constants.upgrades)
        .then((res) => {
          commit("SET_UPGRADES", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
    certifiedChecklist: ({ commit }, category) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", constants.checklist(category.category))
        .then((res) => {
          commit("SET_CHECKLIST", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    checklist: ({ commit }, category) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", constants.checklist(category.category))
        .then((res) => {
          commit("SET_CHECKLIST", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    submitUpgrade: ({ commit }, payload) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", constants.submit, payload)
        .then(() => {
          EventBus.$emit("application-submitted");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    getUpgradeReport: ({ commit }, payload) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", constants.upgradeReport, payload)
        .then((res) => {
          commit("SET_UPGRADE_REPORT", res.data.data[0]);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    getAcceptanceLetter: ({ commit }, payload) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", constants.upgradeAcceptanceLetter, payload)
        .then((res) => {
          commit("MUTATE", {
            state: "acceptanceLetter",
            data: res.data.data[0],
          });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
    verifySubmitOtp: ({ commit }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", ApplicationConstant.verifyOtp, data.otp_code)
        .then(() => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "success",
            message: "Successfully verified Otp",
          });
          // dispatch("submit", data.appData);
          // dispatch("getApplicationsDocument", data.appData.Code);
          EventBus.$emit("applicationverify-success");
        })
        .catch((err) => {
          commit("SET_VERIFY", "failed");
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};
