<template>
  <v-row justify="center">
    <v-dialog v-model="setdialog" width="600" persistent>
      <v-card>
        <v-card-text class="pt-4 pl-6">Draw your signature</v-card-text>
        <canvas @click="setPad" ref="mySignature"></canvas>
        <v-card-actions>
          <v-btn @click="setdialog = false">cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="clearCanvas"
            color="secondary"
            :disabled="!hasSignature"
            >clear</v-btn
          >
          <v-btn
            color="primary"
            :disabled="!hasSignature"
            @click="saveSignature"
            >save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import SignaturePad from "signature_pad";
export default {
  name: "SignaturePad",
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      signaturePad: undefined,
      hasSignature: false,
      mySignature: null,
    };
  },

  computed: {
    setdialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("closeSignatureDialog", val);
      },
    },
    canvas() {
      if (this.$refs.mySignature) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return (this.signaturePad = new SignaturePad(this.$refs.mySignature, {
          minWidth: 1,
          maxWidth: 2,
          penColor: "rgb(66, 133, 244)",
        }));
      }
      return this.signaturePad;
    },
  },
  methods: {
    clearCanvas() {
      this.signaturePad.clear();
      this.hasSignature = false;
    },
    setPad() {
      this.canvas;
      this.checkSignature();
    },
    saveSignature() {
      let data = this.canvas.toDataURL();
      data = data.split(",")[1];
      this.$emit("signatureData", data);
      this.$emit("closeSignatureDialog", false);
    },

    checkSignature() {
      const Canvas = this.$refs.mySignature;
      const context = Canvas.getContext("2d");
      //checking if there are any pixels drawn on the canvas
      const imageData = context.getImageData(0, 0, Canvas.width, Canvas.height);
      const data = imageData.data;
      let hasData = false;
      for (let i = 0; i < data.length; i += 4) {
        if (
          data[i] !== 0 ||
          data[i + 1] !== 0 ||
          data[i + 2] !== 0 ||
          data[i + 3] !== 0
        ) {
          hasData = true;
          break;
        }
      }
      this.hasSignature = hasData;
    },
  },
};
</script>
<style scoped>
canvas {
  display: block;
  width: 500px;
  height: 200px;
  border: 1px solid black;
  margin: 2% auto;
  border-radius: 4%;
}
</style>
