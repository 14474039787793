import Vue from "vue";
import Vuex from "vuex";
import { SearchStore } from "../modules/search";
import { AuthStore } from "../modules/auth";
import { SetupStore } from "../modules/setup";
import { ProfileStore } from "../modules/profile";
import { ApplicationStore } from "../modules/application";
import { CheckListStore } from "../modules/checklist";
import { BillingStore } from "../modules/billing";
import { DashboardStore } from "../modules/dashboard";
import { PaymentHistoryStore } from "../modules/payment-history";
import UpgradeStore from "../modules/upgrade/UpgradeStore";
import { SignatureStore } from "../modules/Signature";
import SubscriptionStore from "../modules/subscriptions/SubscriptionStore";
import CpdStore from "../modules/cpd/CpdStore";
import EventsStore from "../modules/events/EventsStore";
import LicenseStore from "../modules/license/LicenseStore";
import { AdminStore } from "../modules/admin";
/** add the dashboard store here */

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Search: SearchStore,
    Auth: AuthStore,
    Setup: SetupStore,
    Profile: ProfileStore,
    Application: ApplicationStore,
    Checklist: CheckListStore,
    Billing: BillingStore,
    Dashboard: DashboardStore,
    Subscriptions: SubscriptionStore,
    Upgrade: UpgradeStore,
    Signature: SignatureStore,
    Events: EventsStore,
    License: LicenseStore,
    Admin: AdminStore,
    Cpd: CpdStore,
    PaymentHistory: PaymentHistoryStore,
  },
  state: { loading: false },
  mutations: {
    SET_LOADING: (state, payload) => {
      state.loading = payload;
    },
  },
  getters: {
    loading: (state) => state.loading,
  },
  actions: {},
});
