import store from "../../store";
import { EventBus } from "../../utils/eventBus";
import call from "../../service/http";
import constants from "./constants";

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    submitandSetSignaturePad: (context, { namespace }) => {
      store.commit(`${namespace}/SET_ALERT`, null);
      store.commit("SET_LOADING", true, { root: true });
      EventBus.$emit("add-signature-success");
      store.commit("SET_LOADING", false, { root: true });
    },
    saveSignature(context, { namespace, ...payload }) {
      store.commit("SET_LOADING", true, { root: true });
      call("post", constants.signature, payload)
        .then(() => {
          store.commit("SET_LOADING", false, {
            root: true,
          });
          store.commit(`${namespace}/SET_ALERT`, {
            status: "success",
            message: "signature saved successfully",
          });
          EventBus.$emit("initiate-sendingOTP");
        })
        .catch((err) => {
          console.log(err);
          store.commit(`${namespace}/SET_VERIFY`, "failed");
          store.commit("SET_LOADING", false, {
            root: true,
          });
          store.commit(`${namespace}/SET_ALERT`, {
            status: "error",
            message: err.response.data.message,
          });
        });
    },
  },
};
