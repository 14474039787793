<script>
export default {
  name: "approveNames",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    bio: {
      type: Object,
      default: () => {},
    },
  },

  data: function () {
    return {
      form: {
        ProfileID: this.bio.ProfileID,
        LastName: this.bio.LastName,
        FirstName: this.bio.FirstName,
        SecondName: this.bio.SecondName,
      },
      isValid: false,
    };
  },

  computed: {
    rules() {
      return {
        required: (v) => !!v || "Field is required",
      };
    },
  },

  methods: {
    approveNames: function () {
      if (!this.isValid) {
        this.$refs.userForm.validate();
      } else {
        this.$confirm.show({
          title: "Approve name?",
          text: `Your name will be ${this.form.LastName} ${this.form.FirstName} ${this.form.SecondName}.`,
          rejectLabel: "Cancel",
          acceptLabel: "Proceed",
          onConfirm: () => {
            this.$store.dispatch("Profile/approveNames", this.form).then(() => {
              this.closeDialog();
            });
          },
        });
      }
    },

    closeDialog: function () {
      this.$emit("close");
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" persistent width="60%">
    <v-card class="mx-auto">
      <v-card-title>
        <span class="headline">Approve Names</span>
        <v-spacer />
        <v-btn icon color="red" @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="userForm" v-model="isValid">
          <v-container>
            <v-text-field
              v-model="form.LastName"
              :rules="[rules.required]"
              label="Last Name"
              dense
              outlined
              prepend-inner-icon="mdi-account-edit"
              clearable
              hint="If wrong, please update"
              persistent-hint
            />

            <v-text-field
              v-model="form.FirstName"
              :rules="[rules.required]"
              label="First Name"
              dense
              outlined
              prepend-inner-icon="mdi-account-edit"
              clearable
              hint="If wrong, please update"
              persistent-hint
              class="mt-2"
            />

            <v-text-field
              v-model="form.SecondName"
              :rules="[rules.required]"
              label="Second Name"
              dense
              outlined
              prepend-inner-icon="mdi-account-edit"
              clearable
              hint="If wrong, please update"
              persistent-hint
              class="mt-2"
            />
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          @click="approveNames"
          :disabled="$store.getters['loader/loading']"
          :loading="$store.getters['loader/loading']"
          >Approve</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped></style>
