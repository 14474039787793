<template>
  <v-card class="mt-6" flat>
    <template>
      <v-alert v-if="showAlert" type="warning" title="Alert title" dismissible>
        Please Visit your Profile and Revise your payment
        <template #close="{ disable }">
          <v-btn icon @click="showAlert = false" :disabled="disable">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-alert>
    </template>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="checkList"
          class="elevation-1 mx-2"
          hide-default-footer
        >
          <template v-slot:item.checkListType="{ item }">
            <td>
              <h4 class="heading">{{ item.checkListType }}</h4>
              <span class="subtitle-2 grey--text">{{ item.description }}</span>
            </td>
          </template>

          <template v-slot:item.pass="{ item }">
            <td>
              <v-icon :color="item.pass ? 'success' : 'warning'">
                {{ item.pass ? "mdi-check" : "mdi-alert" }}
              </v-icon>
            </td>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              small
              :disabled="item.pass"
              :to="{
                name: 'component',
                params: { name: item.checkListType },
              }"
              color="secondary"
            >
              Complete
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col>
        <v-card class="mx-3" outlined>
          <v-card-title class="text-subtitle-2 justify-center"
            >Help Section.</v-card-title
          >
          <v-divider />
          <v-card-text>
            <ul class="help-section-content">
              <li>
                You have
                <b>{{ completedCheckList.length }}/{{ checkList.length }}</b>
                requirement(s) that are complete.
              </li>
              <li class="mt-1">
                To complete any incomplete requirement on the checklist
                (displayed on your left):
                <ul>
                  <li class="mt-1">
                    Click on the add action to complete a requirement.
                  </li>
                  <li class="mt-1">
                    Once all requirements are complete, Confirm to Upgrade and
                    finally click on continue.
                  </li>
                </ul>
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-divider class="mt-8" />

    <v-card-actions>
      <div>
        <v-checkbox v-model="checkbox" label="I Confirm to upgrade">
          <template v-slot:label> </template>
        </v-checkbox>
      </div>

      <v-spacer />
      <v-btn
        :disabled="!checkbox || !isChecklistComplete"
        @click="submit"
        class="btn-continue"
        color="primary"
      >
        Continue <v-icon right> mdi-arrow-right </v-icon></v-btn
      >
      <v-spacer />
    </v-card-actions>

    <SignaturePadVue
      :dialog="signatureDialog"
      @closeSignatureDialog="canCelSignaturedialog"
      @signatureData="saveSignature"
    />

    <v-dialog v-model="dialog" width="600" persistent>
      <v-card class="pa-7" tile elevation="5">
        <v-card-title> Verify Otp </v-card-title>

        <v-card-text>
          <v-alert
            outlined
            v-if="$store.getters['Application/alert'].status"
            dense
            border="left"
            :type="
              $store.getters['Application/alert'].status === 'success'
                ? 'success'
                : 'error'
            "
            class="mb-10"
          >
            {{ $store.getters["Application/alert"].message }}
          </v-alert>
        </v-card-text>

        <v-form ref="">
          <v-card-text>
            <v-text-field
              dense
              outlined
              label="Otp Code"
              placeholder="Enter Otp code"
              ref="otp_code"
              :rules="rules.required"
              v-model="otp_code"
            />
          </v-card-text>
        </v-form>

        <v-card-actions>
          <v-btn @click="dialog = false" text> Close </v-btn>

          <v-spacer />

          <v-btn @click="VerifyAppOTP" color="primary"> Verify </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { EventBus } from "../../../utils/eventBus";
import { AuthService } from "../../auth";
import SignaturePadVue from "@/modules/Signature/SignaturePad.vue";
export default {
  name: "UpgradeChecklist",
  components: { SignaturePadVue },
  data() {
    return {
      checkbox: false,
      showAlert: false,
      headers: [
        {
          text: "Item",
          align: "start",
          sortable: false,
          value: "checkListType",
        },
        { text: "Status", value: "pass" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      signatureDialog: false,
      otp_code: "",
      dialog: false,
      make_application: false,
      upgradeData: null,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Upgrade/certifiedChecklist", {
        category: v.$route.params.code,
        type: "Upgrade",
      });
      v.$store.dispatch("Upgrade/getPlans");
      v.$store.dispatch("Billing/getBalance");
    });
  },

  mounted() {
    EventBus.$on("application-submitted", () => {
      this.$router.push({
        name: "upgradeList",
        // params: { code: this.applicationDetails.Code },
      });
    });
    EventBus.$on("add-signature-success", () => {
      this.signatureDialog = true;
    });
    EventBus.$on("applicationOtp-success", () => {
      this.dialog = true;
    });
    EventBus.$on("applicationverify-success", () => {
      this.dialog = false;
      this.make_application = true;
      this.$store.dispatch("Upgrade/submitUpgrade", this.upgradeData);
    });
    EventBus.$on("initiate-sendingOTP", () => {
      this.$store.dispatch("Application/sendOTP", {
        userId: AuthService.user.id,
      });
    });
  },

  computed: {
    checkList() {
      return this.$store.getters["Upgrade/checklist"];
    },
    completedCheckList() {
      return this.checkList ? this.checkList.filter((c) => c.pass) : [];
    },
    upgrade() {
      return this.$store.getters["Upgrade/plans"][0];
    },
    isChecklistComplete() {
      let isComplete = true;
      this.$store.getters["Upgrade/checklist"].forEach((check) => {
        if (!check.pass) isComplete = false;
      });

      return isComplete;
    },
    user() {
      return AuthService.user;
    },
    balance() {
      return this.$store.getters["Billing/balance"][0];
    },

    rules() {
      return {
        required: [(v) => !!v || "Field is required"],
      };
    },
  },

  methods: {
    canCelSignaturedialog(val) {
      this.signatureDialog = val;
    },

    saveSignature(data) {
      this.$store.dispatch("Signature/saveSignature", {
        namespace: "Upgrade",
        base64Attachment: data,
        profileID: this.user.profile_id,
      });
    },

    sign() {
      this.signatureDialog = true;
      this.$store.dispatch("Signature/submitandSetSignaturePad", {
        namespace: "Upgrade",
      });
    },

    submit() {
      if (!this.signatureDialog) {
        if (this.balance.BalanceLCY <= 0) {
          this.$confirm.show({
            title: `Submit Application ?`,
            text: `By clicking submit, you confirm that you have read the terms and conditions, that you understand them and that you agree to be bound by them.`,
            rejectLabel: "Cancel",
            acceptLabel: "Submit",
            onConfirm: () => {
              this.upgradeData = {
                amount: this.upgrade.Amount,
                toCategory: this.upgrade.ToCategory,
                memberNo: this.user.profile.MemberNo,
                ProfileID: this.user.profile_id,
                requestedDate: "",
              };

              // this.$store.dispatch("Upgrade/submitUpgrade", data);
              this.sign();
            },
          });
        } else {
          this.showAlert = true;
        }
      }
    },

    VerifyAppOTP() {
      const data = {
        otp_code: { otp_code: this.otp_code },
      };
      this.$store.dispatch("Upgrade/verifySubmitOtp", data);
    },
  },
};
</script>

<style scoped>
.help-section-content {
  color: #000;
  font-size: 13px;
}

.btn-continue {
  width: 30%;
}
</style>
