<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <v-card class="my-5">
          <v-card-title>
            <v-btn text small @click="$router.back()">
              <v-icon left>mdi-arrow-left</v-icon>
              Back
            </v-btn>
            <v-spacer />
            <v-btn
              small
              class="mr-2"
              color="secondary"
              @click="downloadFile(invoice, `INVOICE-${currentDate()}.pdf`)"
            >
              <v-icon left>mdi-download</v-icon>
              Download
            </v-btn>

            <v-btn
              v-if="service && !service.postedReceiptNo"
              @click="pay(service)"
              small
              color="secondary"
            >
              Pay Now
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-title>
          <v-sheet color="`grey" class="pa-3" v-if="invoice === undefined">
            <v-skeleton-loader
              class="mx-auto"
              max-width="300"
              type="card"
            ></v-skeleton-loader>
          </v-sheet>
          <v-sheet
            color="`grey"
            class="pa-3"
            v-if="
              (invoice !== undefined && invoice.includes('Invalid')) ||
              invoice === ''
            "
          >
            <v-skeleton-loader
              class="mx-auto"
              max-width="300"
              type="card"
            ></v-skeleton-loader>
            <span>Invalid receipt No.</span>
          </v-sheet>
          <v-card-text
            class="elevation-1 mb-1"
            v-for="i in pageCount(invoice)"
            :key="i"
          >
            <pdf
              style="width: 100%"
              :src="`data:application/pdf;base64,${invoice}`"
              :page="i"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <checkout />
  </v-container>
</template>

<script>
import pdf from "vue-pdf";
import { appUrl } from "../../../environment/environment";
import FileMixin from "../../../mixins/FileMixin";
import DateMixin from "../../../mixins/DateMixin";
import RouterMixin from "../../../router/RouterMixin";
import Checkout from "@kinetics254/paygate/src/views/Checkout.vue";

export default {
  name: "invoice",
  mixins: [FileMixin, DateMixin, RouterMixin],
  components: { pdf, Checkout },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Billing/getInvoice", v.decodeRoute(to.params.code));
      // if (from.name !== "upgradeList") {
      //   if (!v.service) v.$router.back();
      // }
    });
  },

  computed: {
    invoice() {
      return this.$store.getters["Billing/invoice"];
    },
    isInvoice() {
      return this.invoice || this.invoice !== "";
    },

    service() {
      return [
        ...this.$store.getters["Application/applications"].filter((s) => {
          return s.Code === this.$route.params.code;
        }),
        ...this.$store.getters["Subscriptions/subscriptions"].filter((s) => {
          return s.memberNo === this.$route.params.code;
        }),
      ].shift();
    },
  },

  methods: {
    pay: function (data) {
      let payload = {
        payerID: data.ProfileID,
        sourceDocumentNo: data.Code,
        description: "Application Payment",
        amount: data.Amount,
        accountNo: "",
        success_url: `${appUrl}${
          this.$router.resolve({ name: "dashboard" }).href
        }`,
        fail_url: window.location.href,
      };

      payload = btoa(JSON.stringify(payload));
      // window.location.href = `${payGateUrl}/?payload=${payload}`;
      this.$checkout.pay(payload);
    },
  },
};
</script>

<style scoped></style>
