<template>
  <v-container
    fluid
    class="fill-height d-flex flex-row align-center"
    style="background-color: rgba(255, 255, 255, 0.65)"
  >
    <v-row>
      <v-col md="4" sm="12" offset-md="4">
        <v-card class="pa-5">
          <v-img
            :src="`data:image/png;base64,${$store.getters['Setup/companyInformation'].logo}`"
            height="100"
            contain
            class="mb-5"
          />
          <v-card-title
            class="d-flex flex-row justify-center font-weight-black"
          >
            REGISTER
          </v-card-title>
          <v-card-subtitle class="text-center font-weight-black text-lowercase">
            <span class="text-capitalize">For</span> new
            {{
              isFirm
                ? "Firm application"
                : `${$route.params.type} ${$route.params.code} application`
            }}
          </v-card-subtitle>

          <v-card-text class="text-justify mt-4 pb-0">
            <v-alert
              outlined
              v-if="$store.getters['Auth/alert'].status"
              dense
              border="left"
              :type="
                $store.getters['Auth/alert'].status === 'success'
                  ? 'success'
                  : 'error'
              "
              class="mb-10"
            >
              {{ $store.getters["Auth/alert"].message }}
            </v-alert>

            <v-form v-model="isValid" ref="signupForm">
              <v-text-field
                dense
                outlined
                label="Email Address"
                placeholder="Enter your Email Address"
                v-model="formData.email"
                ref="email"
                :rules="rules.email"
              />

              <v-text-field
                dense
                outlined
                label="Mobile No."
                placeholder="Enter Mobile No."
                v-model="formData.phone"
                ref="phone"
                :rules="rules.phone"
              />

              <v-text-field
                dense
                outlined
                :label="isFirm ? 'Firm`s KRA pin' : 'your ID/Passport No.'"
                :placeholder="
                  isFirm ? 'Enter Firm`s KRA pin' : 'Enter your ID/Passport No.'
                "
                v-model="formData.id_number"
                ref="id_number"
                :rules="isFirm ? rules.firm_number : rules.id_number"
              />

              <v-text-field
                dense
                outlined
                label="Password"
                placeholder="Enter your Password"
                v-model="formData.password"
                ref="password"
                :rules="rules.password"
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              />

              <v-text-field
                dense
                outlined
                label="Confirm Password"
                placeholder="Enter your confirmation password"
                v-model="formData.confirmPassword"
                ref="confirmPassword"
                :rules="rules.confirmPassword"
                :append-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
                :type="showConfirmPassword ? 'text' : 'password'"
                @click:append="showConfirmPassword = !showConfirmPassword"
              />
            </v-form>
          </v-card-text>

          <v-card-actions class="d-flex flex-row justify-end">
            <v-btn @click="openDialog()" block color="primary">
              Create my account
            </v-btn>
            <v-dialog
              persistent
              v-model="dialog"
              max-width="50%"
              v-if="disclaimers"
            >
              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Consent to Share Information
                </v-card-title>
                <v-card-text class="mt-6">
                  {{ disclaimers.description }}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="grey darken-1"
                    text
                    @click="$router.push({ name: 'login' })"
                  >
                    Disagree
                  </v-btn>
                  <v-btn color="error darken-1" @click="register" text>
                    Agree
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RouterMixin from "../../../router/RouterMixin";
import setupMixin from "../../setup/SetupMixin";

export default {
  name: "Register",
  mixins: [RouterMixin, setupMixin],
  data: function () {
    return {
      formData: {
        email: "",
        phone: "",
        id_number: "",
        password: "",
        confirmPassword: "",
      },
      showPassword: false,
      showConfirmPassword: false,
      isFirm: false,
      dialog: false,
      isValid: true,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.getSetups(["getDisclaimers"]);
      v.isFirm = to.params.code === "CORPORATE";
    });
  },

  computed: {
    rules() {
      return {
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        password: [
          (v) => !!v || "Password is required",
          (v) =>
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#~^()`.,;:{}_/%*?&])[A-Za-z\d@$!#~^()`.,;:{}_/%*?&]{8,16}$/.test(
              v
            ) ||
            "Password should contain: Minimum 8 and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and at least one special character(@$!#~^()`.,;:{}_/%*?&)",
        ],
        id_number: [
          (v) => !!v || "ID is required",
          (v) => v.length >= 6 || "ID should be at least 6 characters",
        ],
        firm_number: [(v) => !!v || "Firm`s KRA pin is required"],
        phone: [(v) => !!v || "Phone is required"],
        confirmPassword: [
          (v) => !!v || "Password is required",
          (v) => v === this.formData.password || "Password does not match",
        ],
      };
    },

    requirements() {
      return this.$store.getters["Auth/requirements"];
    },
    disclaimers() {
      return this.$store.getters["Setup/disclaimers"].filter(
        (item) => item.disclaimerType === "Application"
      )[0];
    },
  },

  methods: {
    openDialog() {
      if (!this.isValid) {
        this.$refs.signupForm.validate();
      } else {
        // this.disclaimers ? (this.dialog = true) : 
        this.register();
      }
    },
    register: function () {
      this.dialog = false;
      if (this.isValid)
        this.$store.dispatch("Auth/register", {
          ...this.formData,
          ...{
            category: this.$route.params.code,
            type: this.$route.params.type,
          },
        });
    },
    text: function () {
      if (this.$route.params.code === "TEMP") {
        return "Temp";
      } else {
        return "Graduate";
      }
    },
  },
};
</script>

<style scoped></style>
