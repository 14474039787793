var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"mt-5"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.licenses,"sort-by":"calories","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.minorSettings.license_alternative_caption)+" Applications ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{attrs:{"x-small":"","outlined":"","color":"secondary","to":{
          name: 'licenseDocument',
          params: { code: item.licenseApplicationNo },
        }}},[_vm._v(" view "),_c('v-icon',{attrs:{"color":"secondary","right":"","small":""}},[_vm._v(" mdi-arrow-right ")])],1),(item.proFormaRef !== '')?_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","outlined":"","color":"secondary"},on:{"click":function($event){return _vm.$root.routeTo({
            name: 'invoice',
            params: { code: item.licenseApplicationNo },
          })}}},[_vm._v(" "+_vm._s(item.receiptNo === "" ? "Proforma" : "Invoice")+" "),_c('v-icon',{attrs:{"color":"secondary","right":"","small":""}},[_vm._v(" mdi-file-document ")])],1):_vm._e(),(item.receiptNo === '' && item.licenseBase64 !== '')?_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","outlined":"","color":"secondary"},on:{"click":function($event){return _vm.pay(item)}}},[_c('span',{staticClass:"text-white"},[_vm._v(" Pay Now "),_c('v-icon',[_vm._v("mdi-cash-multiple")])],1)]):_vm._e(),(item.receiptNo !== '')?_c('v-btn',{staticClass:"mlm-2",attrs:{"x-small":"","outlined":"","color":"secondary"},on:{"click":function($event){_vm.$root.routeTo({
            name: 'receipt',
            params: { code: _vm.encodeRoute(item.receiptNo) },
          })}}},[_c('span',{staticClass:"text-white"},[_vm._v(" Receipt "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-receipt")])],1)]):_vm._e()]}}])}),_c('Checkout')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }