import AdminConstants from "./AdminConstants";
import call from "../../service/http";
import AuthService from "@/modules/auth/AuthService";

export default {
  namespaced: true,
  state: {
    settings: {},
    users: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    adminGetters: (state) => (val) => state[val],
  },
  actions: {
    getSettings: ({ commit }) => {
      call("get", AdminConstants.admin_settings)
        .then((res) => {
          commit("MUTATE", { state: "settings", data: res.data.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    addSettings: ({ commit, dispatch }, payload) => {
      commit("SET_LOADING", true, { root: true });
      call("post", AdminConstants.admin_settings, payload)
        .then(() => {
          commit("SET_LOADING", false, { root: true });
          dispatch("getSettings");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("SET_LOADING", false, { root: true });
        });
    },
    updateSettings: ({ commit, dispatch }, payload) => {
      commit("SET_LOADING", true, { root: true });
      call("post", AdminConstants.update_settings, payload)
        .then(() => {
          commit("SET_LOADING", false, { root: true });
          dispatch("getSettings");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("SET_LOADING", false, { root: true });
        });
    },
    getUsers: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", AdminConstants.user_settings)
        .then((res) => {
          commit("SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "users", data: res.data.data });
        })
        .catch((error) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    updateUser: ({ commit, dispatch }, payload) => {
      commit("SET_LOADING", true, { root: true });
      call("post", AdminConstants.update_user(payload.id), payload)
        .then(() => {
          Event.$emit("close-dialog");
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "User updated successfully");
          dispatch("getUsers");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("SET_LOADING", false, { root: true });
        });
    },
    deleteUser: ({ commit, dispatch }, payload) => {
      commit("SET_LOADING", true, { root: true });
      call("post", AdminConstants.delete_user(payload.id), payload)
        .then(() => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "User deleted successfully");
          dispatch("getUsers");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("SET_LOADING", false, { root: true });
        });
    },
    sendPasswordReset: ({ commit, dispatch }, payload) => {
      commit("SET_LOADING", true, { root: true });
      call("post", AdminConstants.send_activation_link, payload)
        .then(() => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Password Reset link sent successfully");
          dispatch("getUsers");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("SET_LOADING", false, { root: true });
        });
    },
    loginAs: ({ commit }, payload) => {
      commit("SET_LOADING", true, { root: true });
      call("post", AdminConstants.login_as, payload)
        .then((res) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Logged in as user successfully");
          AuthService.loginAdmin(res.data.data.token, res.data.data.user);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("SET_LOADING", false, { root: true });
        });
    },
  },
};
