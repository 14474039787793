<template>
  <v-container class="mt-5">
    <alert />
    <v-row v-if="experience && !experience.length">
      <v-container class="px-6" fluid>
        <v-radio-group
          v-model="profileData.hasNoExperience"
          row
          @change="updateProfileExperinece"
        >
          <template v-slot:label>
            <div>
              {{
                user && user.category === "GRADUATE"
                  ? "Are you currently employed?"
                  : "Do you have Work experience?"
              }}
            </div>
          </template>
          <v-radio label="Yes" value="YES"></v-radio>
          <v-radio label="No" value="NO"></v-radio>
        </v-radio-group>
      </v-container>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="experience"
      dense
      class="elevation-1"
      :loading="$store.getters['loading']"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-h6">My Experience</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>

          <v-spacer></v-spacer>
          <v-dialog persistent v-model="dialog" max-width="50%">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                :disabled="
                  profileData.hasNoExperience !== 'YES' &&
                  experience &&
                  !experience.length
                "
              >
                Add Experience
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="primary">
                <span class="text-h5">
                  {{ edit ? "Edit" : "Add" }} Experience
                </span>
              </v-card-title>

              <v-card-text class="mt-10">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        dense
                        outlined
                        v-model="formData.NameOfFirm"
                        :rules="rules.NameOfFirm"
                        ref="NameOfFirm"
                        @input="
                          (val) =>
                            (formData.NameOfFirm =
                              formData.NameOfFirm.toUpperCase())
                        "
                      >
                        <template v-slot:label>
                          <div>
                            Employer
                            <span class="red--text">*</span>
                          </div>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        dense
                        outlined
                        :items="industryGroups"
                        v-model="formData.Sector"
                        :rules="rules.Sector"
                        ref="Sector"
                        item-value="Code"
                        item-text="Code"
                      >
                        <template v-slot:label>
                          <div>
                            Sector
                            <span class="red--text">*</span>
                          </div>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        dense
                        outlined
                        v-model="formData.PositionHeld"
                        :rules="rules.PositionHeld"
                        ref="PositionHeld"
                        @input="
                          (val) =>
                            (formData.PositionHeld =
                              formData.PositionHeld.toUpperCase())
                        "
                      >
                        <template v-slot:label>
                          <div>
                            Position Held
                            <span class="red--text">*</span>
                          </div>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <!-- date picker menu -->
                      <v-menu
                        ref="menu"
                        v-model="FromDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            dense
                            outlined
                            v-model="formData.FromDate"
                            :rules="rules.FromDate"
                            ref="FromDate"
                            label="From Date"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="formData.FromDate"
                          no-title
                          scrollable
                          :max="today"
                          @input="FromDateMenu = false"
                        />
                      </v-menu>
                    </v-col>

                    <v-col v-if="!formData.Current" cols="12" sm="6" md="4">
                      <!-- date picker menu -->
                      <v-menu
                        ref="menu"
                        v-model="ToDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            dense
                            outlined
                            v-model="formData.ToDate"
                            :rules="rules.ToDate"
                            ref="ToDate"
                            label="To Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="formData.ToDate"
                          no-title
                          scrollable
                          :max="today"
                          @input="ToDateMenu = false"
                        />
                      </v-menu>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-switch
                        inset
                        v-model="formData.Current"
                        label="Current"
                        class="mt-0 ml-1"
                      />
                    </v-col>

                    <v-col cols="12" sm="6" md="12">
                      <v-textarea
                        dense
                        outlined
                        v-model="formData.ResponsibilitiesDescription"
                        :rules="rules.ResponsibilitiesDescription"
                        ref="ResponsibilitiesDescription"
                        rows="3"
                      >
                        <template v-slot:label>
                          <div>
                            Key Responsibilities
                            <span class="red--text">*</span>
                          </div>
                        </template>
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="clearForm"> Cancel </v-btn>
                <v-btn color="primary" @click="save"> Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.ToDate="{ item }">
        <v-chip v-if="item.Current" small color="primary"> Current </v-chip>
        <span v-else>{{ item.ToDate }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" color="blue" @click="update(item)">
          mdi-pencil
        </v-icon>
        <v-icon small color="red" @click="remove(item)"> mdi-delete</v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import SetupMixin from "../../../setup/SetupMixin";
import { helper } from "../../../../utils";
import { AuthService } from "../../../auth";
import fileType from "file-type";
import Alert from "../../../../components/Alert";
import moment from "moment";

export default {
  name: "Experience",
  components: { Alert },
  mixins: [SetupMixin],

  data: () => ({
    dialog: false,
    edit: false,
    ToDateMenu: false,
    FromDateMenu: false,
    today: new Date().toISOString().substr(0, 10),
    options: [
      { value: "Educational", label: "Academic" },
      { value: "Professional", label: "Others" },
    ],
    formData: {
      PositionHeld: "",
      Sector: "",
      ResponsibilitiesDescription: "",
      NameOfFirm: "",
      FromDate: "",
      ToDate: "",
      Current: false,
    },
    profileData: {
      hasNoExperience: "",
    },
    headers: [
      {
        text: "PositionHeld",
        align: "start",
        sortable: false,
        value: "PositionHeld",
      },
      { text: "Sector", value: "Sector" },
      { text: "Description", value: "ResponsibilitiesDescription" },
      { text: "NameOfFirm", value: "NameOfFirm" },
      { text: "FromDate", value: "FromDate" },
      { text: "ToDate", value: "ToDate" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  mounted() {
    this.getSetups(["getIndustryGroups"]);
    this.$store.dispatch("Profile/getExperience");
  },

  computed: {
    user() {
      return AuthService.user;
    },
    rules() {
      return {
        PositionHeld: [(v) => !!v || "Position Held is required"],
        Sector: [(v) => !!v || "Sector Year is required"],
        ResponsibilitiesDescription: [
          (v) => !!v || "Description is required",
          (v) => v.length <= 100 || "Max 100 characters reached",
        ],
        NameOfFirm: [(v) => !!v || "Name of Firm Awarded is required"],
        FromDate: [(v) => !!v || "From Date is required"],
        ToDate: [
          (v) => {
            if (!this.formData.Current) !!v || "To Date is required";
          },
          (v) =>
            !!moment(this.formData.FromDate).isBefore(moment(v)) ||
            "End Date Cannot be before Start Date",
        ],
      };
    },

    experience() {
      return this.$store.getters["Profile/experience"];
    },
  },

  methods: {
    updateProfileExperinece() {
      this.$store.dispatch("Profile/updateProfileWorkExperience", {
        ProfileID: AuthService.user.profile.ProfileID,
        hasNoExperience:
          this.profileData.hasNoExperience === "YES" ? false : true,
      });
    },
    clearForm: function () {
      this.formData = {
        PositionHeld: "",
        Sector: "",
        ResponsibilitiesDescription: "",
        NameOfFirm: "",
        FromDate: "",
        ToDate: "",
      };

      this.dialog = false;
      this.edit = false;
    },

    update: function (payload) {
      this.formData = { ...payload };
      this.edit = true;
      this.dialog = true;
    },

    remove(item) {
      this.$confirm.show({
        title: "Delete this Experience?",
        text: `Are you sure you want to delete?`,
        onConfirm: () => {
          this.$store.dispatch("Profile/removeExperience", { ...item });
        },
      });
    },

    save: function () {
      let isValid = true;
      for (const key in this.formData) {
        if (this.$refs[key])
          isValid = isValid ? this.$refs[key].validate(true) : false;
      }

      if (isValid) {
        let data = { ...this.formData };
        data.ProfileID = AuthService.user.profile.ProfileID;
        data.ToDate =
          this.formData.ToDate || new Date().toISOString().slice(0, 10);

        if (this.edit) {
          data.lineNo = parseInt(data.lineNo);
          data.EntryNo = this.formData.EntryNo
            ? parseInt(this.formData.EntryNo)
            : parseInt(this.experience.length + 1);
        }

        this.$store.dispatch(
          `Profile/${this.edit ? "updateWorkExperience" : "addWorkExperience"}`,
          helper.prepareFormData(data)
        );

        this.clearForm();
      }
    },

    downloadFile: async function (attachment, filename = "certification.pdf") {
      const myBuffer = Buffer.from(attachment, "base64");
      await fileType.fromBuffer(myBuffer).then((res) => {
        const path = `data:${res.mime})};base64,${attachment}`;
        const element = document.createElement("a");
        element.setAttribute("href", path);
        element.setAttribute("download", `${filename}`);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      });
    },
  },
};
</script>

<style scoped></style>
